<template>
  <v-dialog
    v-model="modelsEditorDialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Vehicle Model</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="modelDataLocal.make_id"
                :loading="loading"
                :items="makes"
                :search-input.sync="searchMake"
                cache-items
                hide-details="auto"
                :error-messages="errors.make_id"
                label="Make"
                item-text="name"
                item-value="id"
                outlined
                dense
                @keyup.enter="triggerSearchMake"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="modelDataLocal.name"
                label="Model Name"
                hide-details="auto"
                :error-messages="errors.name"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="$emit('update:models-editor-dialog', false)"
        >
          Close
        </v-btn>
        <v-btn
          color="success"
          :disabled="saving"
          @click="saveModel"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { watch, ref } from '@vue/composition-api'
import axios from '@axios'
import useMakes from './useMakes'

export default {
  props: {
    modelsEditorDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    modelData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const saving = ref(false)
    const errors = ref([])
    const modelDataLocal = ref({})
    modelDataLocal.value = JSON.parse(JSON.stringify(props.modelData))

    const { loading, makes, getMakes, searchMake } = useMakes()

    const saveModel = () => {
      const { name, make_id } = modelDataLocal.value
      const formData = {
        name,
        make_id,
      }
      saving.value = true
      if (modelDataLocal.value.id) {
        // update
        axios
          .put(`/make-models/${modelDataLocal.value.id}`, formData)
          .then(_ => {
            saving.value = false
            emit('saveModel')
            emit('update:models-editor-dialog', false)
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      } else {
        // new
        axios
          .post('/make-models', formData)
          .then(_ => {
            saving.value = false
            emit('saveModel')
            emit('update:models-editor-dialog', false)
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      }
    }

    const triggerSearchMake = () => {
      getMakes({ searchQuery: searchMake.value })
    }

    watch(
      () => props.modelsEditorDialog,
      () => {
        modelDataLocal.value = JSON.parse(JSON.stringify(props.modelData))
        errors.value = []
        if (modelDataLocal.value.make) {
          makes.value = [modelDataLocal.value.make]
        }
      },
    )

    // watch(searchMake, query => {
    //   if (query.trim() !== '') {
    //     getMakes()
    //   }
    // })

    return {
      // variables
      errors,
      loading,
      searchMake,
      makes,
      modelDataLocal,
      saving,

      // functions
      getMakes,
      saveModel,
      triggerSearchMake,
    }
  },
}
</script>
